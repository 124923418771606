.p11440322h-g-m-home-admin-viewmemberstabedit-container {
  min-height: 100vh;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-p11440322h-g-m-home-admin-viewmemberstabedit {
  width: 100%;
  height: 1700px;
  display: flex;
  /*overflow: hidden;*/
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-gray-white);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame331 {
  top: 136px;
  left: 143px;
  width: 1155px;
  height: 253px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-group50 {
  top: 0px;
  left: 0px;
  width: 1155px;
  height: 216px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-group52 {
  top: 0px;
  left: 0px;
  width: 1155px;
  height: 171px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame329 {
  top: 0px;
  left: 0px;
  width: 1155px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 38px;
  margin-right: 636px;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame-button {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-parent-button {
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 5px;
  justify-content: center;
  background-color: var(--dl-color-global_blue-blue500);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-button-internal {
  display: flex;
  padding: 12px 22px;
  position: relative;
  align-items: center;
  border-color: transparent;
  justify-content: center;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frametextbox {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text02 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: normal;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text04 {
  top: 60px;
  color: var(--dl-color-global_gray-gray500);
  width: 1155px;
  height: auto;
  position: absolute;
  align-self: stretch;
  text-align: left;
  line-height: 31px;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text-field1on-white1single-linewith-icona-inactive-copy {
  top: 103px;
  left: 0px;
  width: 440px;
  height: 68px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-container1 {
  top: 0px;
  left: 0px;
  width: 440px;
  height: 48px;
  position: absolute;
  border-color: rgba(102, 103, 107, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-state {
  top: 0px;
  left: 0px;
  width: 440px;
  height: 48px;
  position: absolute;
  border-color: transparent;
  border-radius: 5px;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-focus {
  top: -2px;
  left: -2px;
  width: 444px;
  height: 52px;
  position: absolute;
  border-color: transparent;
  border-radius: 7px;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-icon {
  top: 12px;
  left: 404px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text06 {
  top: 21px;
  left: 12px;
  color: var(--dl-color-global_gray-gray800);
  width: 384px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 24px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text08 {
  top: 6px;
  left: 12px;
  color: rgba(52, 54, 59, 1);
  width: 384px;
  height: auto;
  position: absolute;
  font-size: 12px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 15px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-tabs {
  top: 174px;
  left: 0px;
  width: 1155px;
  display: flex;
  position: absolute;
  align-items: flex-end;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: center;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-framebase-scrollable-tab-text-elements {
  width: 577.5px;
  display: flex;
  padding: 6px 0 0 0;
  position: relative;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 0px;
  border-radius: 5px 5px 0 0;
  flex-direction: column;
  justify-content: flex-end;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-tab {
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
  justify-content: flex-end;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-tab-content {
  display: flex;
  padding: 4px 16px 5px 16px;
  overflow: hidden;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: flex-end;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text10 {
  color: var(--dl-color-global_gray-gray500);
  width: 183px;
  height: auto;
  align-self: auto;
  text-align: center;
  line-height: 16px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-tab-bar {
  width: auto;
  height: 1px;
  position: relative;
  align-self: stretch;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-framebase-scrollable-tab-text-elements1 {
  width: 577.5px;
  display: flex;
  padding: 6px 0 0 0;
  position: relative;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 5px 5px 0 0;
  flex-direction: column;
  justify-content: flex-end;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-tab1 {
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
  justify-content: flex-end;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-tab-content1 {
  display: flex;
  padding: 4px 16px 2px 16px;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: flex-end;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text12 {
  color: var(--dl-color-global_blue-blue500);
  width: 183px;
  height: auto;
  align-self: auto;
  text-align: center;
  line-height: 16px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-tab-bar1 {
  width: auto;
  height: 4px;
  position: relative;
  align-self: stretch;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-header {
  top: 0px;
  left: 0px;
  width: 1440px;
  height: 56px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: space-between;
  background-color: var(--dl-color-global_blue-blue500);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-primary {
  top: 0px;
  left: 0px;
  width: 839px;
  height: 56px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-navigation-logo {
  top: 0px;
  left: 0px;
  width: 200px;
  height: 56px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-logo-background {
  top: 0px;
  left: 0px;
  width: 193px;
  height: 56px;
  position: absolute;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text14 {
  top: 16px;
  left: 83px;
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 26px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-simple {
  top: 9px;
  left: 12px;
  width: 41px;
  height: 39px;
  position: absolute;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-link-items {
  top: 0px;
  left: 200px;
  width: 639px;
  height: 56px;
  display: flex;
  padding: 16px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-framebase-navigation-links {
  top: 16px;
  left: 0px;
  width: 95px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text16 {
  top: 0px;
  left: 0px;
  width: 71px;
  height: 24px;
  display: flex;
  padding: 2.5px 4px 2.5px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text17 {
  top: 2.5px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand {
  top: 0px;
  left: 71px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-framebase-navigation-links1 {
  top: 16px;
  left: 119px;
  width: 94px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text19 {
  top: 0px;
  left: 0px;
  width: 70px;
  height: 24px;
  display: flex;
  padding: 2.5px 4px 2.5px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text20 {
  top: 2.5px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand01 {
  top: 0px;
  left: 70px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-framebase-navigation-links2 {
  top: 16px;
  left: 237px;
  width: 104px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text22 {
  top: 0px;
  left: 0px;
  width: 80px;
  height: 24px;
  display: flex;
  padding: 2.5px 4px 2.5px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text23 {
  top: 2.5px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand02 {
  top: 0px;
  left: 80px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-framebase-navigation-links3 {
  top: 16px;
  left: 365px;
  width: 103px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text25 {
  top: 0px;
  left: 0px;
  width: 79px;
  height: 24px;
  display: flex;
  padding: 2.5px 4px 2.5px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text26 {
  top: 2.5px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand03 {
  top: 0px;
  left: 79px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-framebase-navigation-links4 {
  top: 16px;
  left: 492px;
  width: 87px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text28 {
  top: 0px;
  left: 0px;
  width: 63px;
  height: 24px;
  display: flex;
  padding: 2.5px 4px 2.5px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text29 {
  top: 2.5px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand04 {
  top: 0px;
  left: 63px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-framebase-navigation-links5 {
  top: 16px;
  left: 603px;
  width: 36px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text31 {
  top: 0px;
  left: 0px;
  width: 36px;
  height: 24px;
  display: flex;
  padding: 2.5px 4px 2.5px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame-utility {
  top: 0px;
  left: 1203px;
  width: 237px;
  height: 56px;
  display: flex;
  padding: 16px 24px 16px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
  background-color: var(--dl-color-global_blue-blue500);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-framebase-navigation-links6 {
  top: 16px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-search {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-framebase-navigation-links7 {
  top: 16px;
  left: 48px;
  width: 165px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-framebase-navigation-links8 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-person {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame1 {
  top: 0px;
  left: 24px;
  width: 117px;
  height: 24px;
  display: flex;
  padding: 2.5px 4px 2.5px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text32 {
  top: 2.5px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand05 {
  top: 0px;
  left: 141px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame52 {
  top: 230px;
  width: 1141px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame6162 {
  top: 230px;
  left: 142px;
  width: 1141px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame51 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 22px;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame332 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 84px;
  flex-direction: column;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text34 {
  color: var(--dl-color-global_gray-gray600);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 28px;
  margin-right: 0;
  margin-bottom: 8px;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text36 {
  color: var(--dl-color-global_gray-gray600);
  width: 903px;
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 21px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text38 {
  color: var(--dl-color-gray-black);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: SemiBold;
  text-align: right;
  font-family: Montserrat;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame55 {
  top: 75px;
  width: 1141px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame-button1 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 22px;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-parent-button1 {
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(185, 204, 227, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  justify-content: center;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-button-internal1 {
  display: flex;
  padding: 12px 22px;
  position: relative;
  align-items: center;
  border-color: transparent;
  justify-content: center;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-add {
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 11px;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frametextbox1 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text40 {
  color: var(--dl-color-global_blue-blue200);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: normal;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame-button2 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame551 {
  top: 280px;
  width: 1155px;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
}

.p11440322h-g-m-home-admin-viewmemberstabedit-frame616 {
  top: 280px;
  left: 142px;
  width: 1155px;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-table {
  width: 1155px;
  display: flex;
  overflow: hidden;
  position: relative;
  box-sizing: content-box;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(198, 199, 204, 1);
  border-style: solid;
  border-width: 1px;
  margin-bottom: 43px;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-column {
  width: 420px;
  height: 360px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(198, 199, 204, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell {
  top: 0px;
  left: 0px;
  width: 420px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-gray-white);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text42 {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  flex-grow: 1;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 10px;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand06 {
  width: 24px;
  height: 24px;
  position: relative;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell01 {
  top: 45px;
  left: 0px;
  width: 420px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-global_gray-gray100);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame172 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text44 {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 0;
  margin-bottom: 0px;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell02 {
  top: 90px;
  left: 0px;
  width: 420px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-gray-white);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame1721 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text46 {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 0;
  margin-bottom: 0px;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell03 {
  top: 135px;
  left: 0px;
  width: 420px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-global_gray-gray100);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame1722 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text48 {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 0;
  margin-bottom: 0px;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell04 {
  top: 180px;
  left: 0px;
  width: 420px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-gray-white);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame1723 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text50 {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 0;
  margin-bottom: 0px;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell05 {
  top: 225px;
  left: 0px;
  width: 420px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-global_gray-gray100);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame1724 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text52 {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 0;
  margin-bottom: 0px;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell06 {
  top: 270px;
  left: 0px;
  width: 420px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-gray-white);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame1725 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text54 {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 0;
  margin-bottom: 0px;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell07 {
  top: 315px;
  left: 0px;
  width: 420px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-global_gray-gray100);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame1726 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text56 {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 0;
  margin-bottom: 0px;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-column1 {
  width: 128px;
  height: 360px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(198, 199, 204, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell08 {
  top: 0px;
  left: 0px;
  width: 128px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-gray-white);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text58 {
  color: var(--dl-color-global_gray-gray600);
  height: auto;
  flex-grow: 1;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 10px;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand07 {
  width: 24px;
  height: 24px;
  position: relative;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell09 {
  top: 45px;
  left: 0px;
  width: 128px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-global_gray-gray100);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text60 {
  color: var(--dl-color-global_gray-gray600);
  height: auto;
  flex-grow: 1;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 10px;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand08 {
  width: 24px;
  height: 24px;
  position: relative;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell10 {
  top: 90px;
  left: 0px;
  width: 128px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-gray-white);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text62 {
  color: var(--dl-color-global_gray-gray600);
  height: auto;
  flex-grow: 1;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 10px;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand09 {
  width: 24px;
  height: 24px;
  position: relative;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell11 {
  top: 135px;
  left: 0px;
  width: 128px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-global_gray-gray100);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text64 {
  color: var(--dl-color-global_gray-gray600);
  height: auto;
  flex-grow: 1;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 10px;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand10 {
  width: 24px;
  height: 24px;
  position: relative;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell12 {
  top: 180px;
  left: 0px;
  width: 128px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-gray-white);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text66 {
  color: var(--dl-color-global_gray-gray600);
  height: auto;
  flex-grow: 1;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 10px;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand11 {
  width: 24px;
  height: 24px;
  position: relative;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell13 {
  top: 225px;
  left: 0px;
  width: 128px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-global_gray-gray100);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text68 {
  color: var(--dl-color-global_gray-gray600);
  height: auto;
  flex-grow: 1;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 10px;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand12 {
  width: 24px;
  height: 24px;
  position: relative;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell14 {
  top: 270px;
  left: 0px;
  width: 128px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-gray-white);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text70 {
  color: var(--dl-color-global_gray-gray600);
  height: auto;
  flex-grow: 1;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 10px;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand13 {
  width: 24px;
  height: 24px;
  position: relative;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell15 {
  top: 315px;
  left: 0px;
  width: 128px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-global_gray-gray100);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text72 {
  color: var(--dl-color-global_gray-gray600);
  height: auto;
  flex-grow: 1;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 10px;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand14 {
  width: 24px;
  height: 24px;
  position: relative;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-column2 {
  width: 500px;
  height: 360px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(198, 199, 204, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell16 {
  top: 0px;
  left: 0px;
  width: 500px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-gray-white);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text74 {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  flex-grow: 1;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 10px;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand15 {
  width: 24px;
  height: 24px;
  position: relative;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell17 {
  top: 45px;
  left: 0px;
  width: 500px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-global_gray-gray100);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text76 {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  flex-grow: 1;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell18 {
  top: 90px;
  left: 0px;
  width: 500px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-gray-white);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text78 {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  flex-grow: 1;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell19 {
  top: 135px;
  left: 0px;
  width: 500px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-global_gray-gray100);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text80 {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  flex-grow: 1;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell20 {
  top: 180px;
  left: 0px;
  width: 500px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-gray-white);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text82 {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  flex-grow: 1;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell21 {
  top: 225px;
  left: 0px;
  width: 500px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-global_gray-gray100);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text84 {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  flex-grow: 1;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell22 {
  top: 270px;
  left: 0px;
  width: 500px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-gray-white);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text86 {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  flex-grow: 1;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-cell23 {
  top: 315px;
  left: 0px;
  width: 500px;
  height: 45px;
  display: flex;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-global_gray-gray100);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text88 {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  flex-grow: 1;
  align-self: auto;
  text-align: left;
  line-height: 20px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-column3 {
  width: 109px;
  height: 360px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(198, 199, 204, 1);
  border-style: solid;
  border-width: 1px;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon {
  top: 0px;
  left: -5.5px;
  width: 120px;
  height: 45px;
  display: flex;
  padding: 0 24px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon1 {
  width: 72px;
  height: 45px;
  display: flex;
  padding: 0 24px;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: center;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-trash {
  width: 24px;
  height: 24px;
  position: relative;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon2 {
  top: 45px;
  left: -11px;
  width: 131px;
  height: 45px;
  display: flex;
  padding: 0 24px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: center;
  background-color: var(--dl-color-global_gray-gray100);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-trash1 {
  width: 24px;
  height: 24px;
  position: relative;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon3 {
  top: 90px;
  left: -11px;
  width: 131px;
  height: 45px;
  display: flex;
  padding: 0 24px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-trash2 {
  width: 24px;
  height: 24px;
  position: relative;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon4 {
  top: 135px;
  left: -11px;
  width: 131px;
  height: 45px;
  display: flex;
  padding: 0 24px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: center;
  background-color: var(--dl-color-global_gray-gray100);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-trash3 {
  width: 24px;
  height: 24px;
  position: relative;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon5 {
  top: 180px;
  left: -11px;
  width: 131px;
  height: 45px;
  display: flex;
  padding: 0 24px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-trash4 {
  width: 24px;
  height: 24px;
  position: relative;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon6 {
  top: 225px;
  left: -11px;
  width: 131px;
  height: 45px;
  display: flex;
  padding: 0 24px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: center;
  background-color: var(--dl-color-global_gray-gray100);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-trash5 {
  width: 24px;
  height: 24px;
  position: relative;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon7 {
  top: 270px;
  left: 18.5px;
  width: 72px;
  height: 45px;
  display: flex;
  padding: 0 24px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-trash6 {
  width: 24px;
  height: 24px;
  position: relative;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon8 {
  top: 315px;
  left: -11px;
  width: 131px;
  height: 45px;
  display: flex;
  padding: 0 24px;
  overflow: hidden;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: center;
  background-color: var(--dl-color-global_gray-gray100);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-trash7 {
  width: 24px;
  height: 24px;
  position: relative;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-group54 {
  width: 1155px;
  height: 48px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: stretch;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame27 {
  top: 0px;
  left: 0px;
  width: 1155px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame-button3 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 22px;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-parent-button2 {
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 5px;
  justify-content: center;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-button-internal2 {
  display: flex;
  padding: 12px 22px;
  position: relative;
  align-items: center;
  border-color: transparent;
  justify-content: center;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frametextbox2 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text90 {
  color: var(--dl-color-global_blue-blue200);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: normal;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frame-button4 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-parent-button3 {
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 5px;
  justify-content: center;
  background-color: var(--dl-color-global_blue-blue200);
}
.p11440322h-g-m-home-admin-viewmemberstabedit-button-internal3 {
  display: flex;
  padding: 12px 22px;
  position: relative;
  align-items: center;
  border-color: transparent;
  justify-content: center;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-frametextbox3 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.p11440322h-g-m-home-admin-viewmemberstabedit-text92 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: normal;
  margin-right: 0;
  margin-bottom: 0;
}
