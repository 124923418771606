:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #D9D9D9;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-100: #003EB3;
  --dl-color-primary-300: #0074F0;
  --dl-color-primary-500: #14A9FF;
  --dl-color-primary-700: #85DCFF;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-xxlarge: 288px;
  --dl-color-global-black: rgba(0, 0, 0, 1);
  --dl-color-global-white: rgba(255, 255, 255, 1);
  --dl-size-size-maxwidth: 1400px;
  --dl-radius-radius-round: 50%;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-color-temp-placeholder: rgba(123, 97, 255, 1);
  --dl-space-space-threeunits: 48px;
  --dl-color-global_red-red100: rgba(252, 230, 227, 1);
  --dl-color-global_red-red300: rgba(247, 167, 166, 1);
  --dl-color-global_red-red500: rgba(237, 27, 52, 1);
  --dl-color-global_red-red800: rgba(196, 19, 42, 1);
  --dl-color-scrim_red-30red300: rgba(247, 167, 166, 0.30000001192092896);
  --dl-color-scrim_red-30red500: rgba(237, 27, 52, 0.30000001192092896);
  --dl-color-scrim_red-30red800: rgba(196, 19, 42, 0.30000001192092896);
  --dl-color-global_blue-blue100: rgba(230, 238, 246, 1);
  --dl-color-global_blue-blue200: rgba(185, 204, 227, 1);
  --dl-color-global_blue-blue300: rgba(77, 135, 191, 1);
  --dl-color-global_blue-blue500: rgba(0, 84, 164, 1);
  --dl-color-global_blue-blue800: rgba(0, 54, 114, 1);
  --dl-color-global_gray-gray100: rgba(240, 242, 243, 1);
  --dl-color-global_gray-gray300: rgba(198, 199, 204, 1);
  --dl-color-global_gray-gray500: rgba(102, 103, 107, 1);
  --dl-color-global_gray-gray600: rgba(68, 70, 75, 1);
  --dl-color-global_gray-gray800: rgba(52, 54, 59, 1);
  --dl-color-scrim_black-15black: rgba(0, 0, 0, 0.15000000596046448);
  --dl-color-scrim_black-30black: rgba(0, 0, 0, 0.30000001192092896);
  --dl-color-scrim_black-50black: rgba(0, 0, 0, 0.5);
  --dl-color-scrim_white-30white: rgba(255, 255, 255, 0.30000001192092896);
  --dl-color-scrim_white-60white: rgba(255, 255, 255, 0.6000000238418579);
  --dl-color-scrim_white-80white: rgba(255, 255, 255, 0.800000011920929);
  --dl-color-temp-vipmarketcolor: rgba(159, 1, 74, 1);
  --dl-color-scrim_blue-30blue100: rgba(230, 238, 246, 0.30000001192092896);
  --dl-color-scrim_blue-30blue200: rgba(140, 177, 213, 0.30000001192092896);
  --dl-color-scrim_blue-30blue300: rgba(65, 116, 210, 0.30000001192092896);
  --dl-color-scrim_blue-30blue500: rgba(0, 84, 164, 0.30000001192092896);
  --dl-color-scrim_blue-30blue800: rgba(0, 54, 114, 0.30000001192092896);
  --dl-color-scrim_gray-30gray100: rgba(240, 242, 243, 0.30000001192092896);
  --dl-color-scrim_gray-30gray300: rgba(198, 199, 204, 0.30000001192092896);
  --dl-color-scrim_gray-30gray500: rgba(102, 103, 107, 0.30000001192092896);
  --dl-color-scrim_gray-30gray800: rgba(68, 70, 75, 0.30000001192092896);
  --dl-color-global_green-green100: rgba(235, 251, 222, 1);
  --dl-color-global_green-green300: rgba(178, 236, 153, 1);
  --dl-color-global_green-green500: rgba(96, 193, 80, 1);
  --dl-color-global_green-green800: rgba(37, 128, 57, 1);
  --dl-space-space-oneandhalfunits: 24px;
  --dl-color-global_yellow-yellow100: rgba(252, 251, 224, 1);
  --dl-color-global_yellow-yellow300: rgba(246, 224, 137, 1);
  --dl-color-global_yellow-yellow500: rgba(239, 170, 0, 1);
  --dl-color-global_yellow-yellow800: rgba(173, 107, 0, 1);
  --dl-color-temp-learningcentercolor: rgba(0, 118, 122, 1);
  --dl-color-productcolors-extras-joint: rgba(119, 66, 18, 1);
  --dl-color-productcolors-extras-dental: rgba(0, 140, 149, 1);
  --dl-color-productcolors-extras-kidney: rgba(166, 85, 35, 1);
  --dl-color-productcolors-extras-digestive: rgba(135, 38, 81, 1);
  --dl-color-productcolors-extras-oncprimary: rgba(219, 55, 60, 1);
  --dl-color-interactivestates_onblue-pressed: rgba(34, 83, 159, 1);
  --dl-color-productcolors-extras-restorative: rgba(155, 90, 26, 1);
  --dl-color-interactivestates_onblue-selected: rgba(34, 83, 159, 1);
  --dl-color-interactivestates_onwhite-pressed: rgba(255, 255, 255, 1);
  --dl-color-productcolors-extras-dermcomplete: rgba(39, 116, 174, 1);
  --dl-color-productcolors-extras-oncsecondary: rgba(246, 144, 126, 1);
  --dl-color-interactivestates_onblue-activated: rgba(34, 83, 159, 1);
  --dl-color-interactivestates_onwhite-selected: rgba(255, 255, 255, 1);
  --dl-color-interactivestates_onwhite-activated: rgba(255, 255, 255, 1);
  --dl-color-productcolors-extras-urinaryprimary: rgba(239, 106, 0, 1);
  --dl-color-productcolors-extras-weightreduction: rgba(37, 114, 38, 1);
  --dl-color-productcolors-extras-urinarysecondary: rgba(199, 83, 0, 1);
  --dl-color-interactivestates_interactivestate-zerofill-zerofill: rgba(255, 255, 255, 0.00009999999747378752);
}
.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: none !important;
}
.Content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Heading {
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.DesktopTitle {
  font-size: 45px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 800px;
  font-stretch: normal;
}
.DesktopH1 {
  font-size: 32px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 800px;
  font-stretch: normal;
}
.DesktopH2 {
  font-size: 24px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 800px;
  font-stretch: normal;
}
.DesktopH3 {
  font-size: 20px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700px;
  font-stretch: normal;
}
.Subhead1 {
  font-size: 15px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 600px;
  font-stretch: normal;
}
.Subhead2 {
  font-size: 12px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400px;
  font-stretch: normal;
}
.MobileTitle {
  font-size: 32px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 800px;
  font-stretch: normal;
}
.MobileH1 {
  font-size: 24px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 800px;
  font-stretch: normal;
}
.MobileH2 {
  font-size: 18px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 800px;
  font-stretch: normal;
}
.MobileH3 {
  font-size: 16px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700px;
  font-stretch: normal;
}
.Paragraph1 {
  font-size: 18px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400px;
  font-stretch: normal;
}
.Paragraph2 {
  font-size: 16px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400px;
  font-stretch: normal;
}
.Paragraph3 {
  font-size: 14px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400px;
  font-stretch: normal;
}
.UIObjectsButton {
  font-size: 18px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 600px;
  font-stretch: normal;
}
.UIObjectsText1 {
  font-size: 18px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400px;
  font-stretch: normal;
}
.UIObjectsText1Bold {
  font-size: 18px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700px;
  font-stretch: normal;
}
.UIObjectsText2 {
  font-size: 16px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400px;
  font-stretch: normal;
}
.UIObjectsText2Bold {
  font-size: 16px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700px;
  font-stretch: normal;
}
.UIObjectsText3 {
  font-size: 14px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400px;
  font-stretch: normal;
}
.UIObjectsText3Bold {
  font-size: 14px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700px;
  font-stretch: normal;
}
.UIObjectsText3BOLDCAPS-1 {
  font-size: 14px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 600px;
  font-stretch: normal;
}
.UIObjectsText3BOLDCAPS-2 {
  font-size: 14px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 600px;
  font-stretch: normal;
}
.UIObjectsText4 {
  font-size: 12px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400px;
  font-stretch: normal;
}
.DataTableColumnHeading {
  font-size: 16px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700px;
  font-stretch: normal;
}
.DataTableLink {
  font-size: 16px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700px;
  font-stretch: normal;
}
.DataTableAlphanumeric {
  font-size: 16px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400px;
  font-stretch: normal;
}
.DataTableNumeric {
  font-size: 16px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400px;
  font-stretch: normal;
}
