.p11440312h-g-m-home-adminmember-vieweditgroupnickname-container {
  min-height: 100vh;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-p11440312h-g-m-home-adminmember-vieweditgroupnickname {
  width: 100%;
  /* height: 1398px; */
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-gray-white);
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-header {
  top: 0px;
  left: 0px;
  width: 1440px;
  height: 56px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: space-between;
  background-color: var(--dl-color-global_blue-blue500);
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-primary {
  top: 0px;
  left: 0px;
  width: 839px;
  height: 56px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-navigation-logo {
  top: 0px;
  left: 0px;
  width: 200px;
  height: 56px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-logo-background {
  top: 0px;
  left: 0px;
  width: 193px;
  height: 56px;
  position: absolute;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text {
  top: 16px;
  left: 83px;
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 26px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-simple {
  top: 9px;
  left: 12px;
  width: 41px;
  height: 39px;
  position: absolute;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-link-items {
  top: 0px;
  left: 200px;
  width: 639px;
  height: 56px;
  display: flex;
  padding: 16px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-navigation-links {
  top: 16px;
  left: 0px;
  width: 95px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text002 {
  top: 0px;
  left: 0px;
  width: 71px;
  height: 24px;
  display: flex;
  padding: 2.5px 4px 2.5px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text003 {
  top: 2.5px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-directionexpand {
  top: 0px;
  left: 71px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-navigation-links1 {
  top: 16px;
  left: 119px;
  width: 94px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text005 {
  top: 0px;
  left: 0px;
  width: 70px;
  height: 24px;
  display: flex;
  padding: 2.5px 4px 2.5px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text006 {
  top: 2.5px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-directionexpand1 {
  top: 0px;
  left: 70px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-navigation-links2 {
  top: 16px;
  left: 237px;
  width: 104px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text008 {
  top: 0px;
  left: 0px;
  width: 80px;
  height: 24px;
  display: flex;
  padding: 2.5px 4px 2.5px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text009 {
  top: 2.5px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-directionexpand2 {
  top: 0px;
  left: 80px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-navigation-links3 {
  top: 16px;
  left: 365px;
  width: 103px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text011 {
  top: 0px;
  left: 0px;
  width: 79px;
  height: 24px;
  display: flex;
  padding: 2.5px 4px 2.5px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text012 {
  top: 2.5px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-directionexpand3 {
  top: 0px;
  left: 79px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-navigation-links4 {
  top: 16px;
  left: 492px;
  width: 87px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text014 {
  top: 0px;
  left: 0px;
  width: 63px;
  height: 24px;
  display: flex;
  padding: 2.5px 4px 2.5px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text015 {
  top: 2.5px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-directionexpand4 {
  top: 0px;
  left: 63px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-navigation-links5 {
  top: 16px;
  left: 603px;
  width: 36px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text017 {
  top: 0px;
  left: 0px;
  width: 36px;
  height: 24px;
  display: flex;
  padding: 2.5px 4px 2.5px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame-utility {
  top: 0px;
  left: 1203px;
  width: 237px;
  height: 56px;
  display: flex;
  padding: 16px 24px 16px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
  background-color: var(--dl-color-global_blue-blue500);
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-navigation-links6 {
  top: 16px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-search {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-navigation-links7 {
  top: 16px;
  left: 48px;
  width: 165px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-navigation-links8 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-person {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame1 {
  top: 0px;
  left: 24px;
  width: 117px;
  height: 24px;
  display: flex;
  padding: 2.5px 4px 2.5px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text018 {
  top: 2.5px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-directionexpand5 {
  top: 0px;
  left: 141px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame51 {
  top: 419px;
  left: 142px;
  width: 1156px;
  height: 63px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-section1 {
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-bottom: 22px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-textandsupportingtext {
  height: 63px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text020 {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 28px;
  margin-right: 0;
  margin-bottom: 8px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text022 {
  color: var(--dl-color-global_gray-gray500);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 31px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-rectangle-copy {
  width: 1150px;
  height: 1px;
  position: relative;
  border-color: rgba(150, 150, 150, 1);
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame334 {
  top: 528px;
  left: 142px;
  width: 1150px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame54 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 15px;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame511 {
  width: 1150px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-bottom: 10px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-textandsupportingtext1 {
  width: 1038px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 15px;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text024 {
  color: var(--dl-color-global_gray-gray800);
  width: 891px;
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 26px;
  margin-right: 0;
  margin-bottom: 2px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text026 {
  color: var(--dl-color-global_gray-gray800);
  width: 891px;
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 31px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-rectangle-copy1 {
  width: 1150px;
  height: 1px;
  position: relative;
  border-color: rgba(150, 150, 150, 1);
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame541 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 15px;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame512 {
  width: 1150px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-bottom: 10px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-textandsupportingtext2 {
  width: 1038px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 15px;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text028 {
  color: var(--dl-color-global_gray-gray800);
  width: 891px;
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 26px;
  margin-right: 0;
  margin-bottom: 2px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text030 {
  color: var(--dl-color-global_gray-gray800);
  width: 891px;
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 31px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-content {
  display: flex;
  padding: 2px 0;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame-button {
  width: 94px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-parent-button {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  align-items: center;
  border-color: transparent;
  border-radius: 5px;
  justify-content: center;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-button-internal {
  display: flex;
  padding: 12px 22px;
  position: relative;
  align-items: center;
  border-color: transparent;
  justify-content: center;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-edit {
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 11px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frametextbox {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text032 {
  color: var(--dl-color-global_blue-blue500);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: normal;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-rectangle-copy2 {
  width: 1150px;
  height: 1px;
  position: relative;
  border-color: rgba(150, 150, 150, 1);
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame513 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-textandsupportingtext3 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 15px;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text034 {
  color: var(--dl-color-global_gray-gray800);
  width: 891px;
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 26px;
  margin-right: 0;
  margin-bottom: 2px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text036 {
  color: var(--dl-color-global_gray-gray800);
  width: 891px;
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 31px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-rectangle-copy3 {
  width: 1150px;
  height: 1px;
  position: relative;
  border-color: rgba(150, 150, 150, 1);
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame330 {
  top: 148px;
  left: 142px;
  width: 1155px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame329 {
  width: 1155px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-bottom: 12px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text038 {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 38px;
  margin-right: 636px;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame-button1 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-parent-button1 {
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 5px;
  justify-content: center;
  background-color: var(--dl-color-global_blue-blue500);
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-button-internal1 {
  display: flex;
  padding: 12px 22px;
  position: relative;
  align-items: center;
  border-color: transparent;
  justify-content: center;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frametextbox1 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text040 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: normal;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text042 {
  color: var(--dl-color-global_gray-gray500);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 31px;
  margin-right: 0;
  margin-bottom: 12px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text-field1on-white1single-linewith-icona-inactive-copy {
  width: 440px;
  height: 68px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-bottom: 12px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-container1 {
  top: 0px;
  left: 0px;
  width: 440px;
  height: 48px;
  position: absolute;
  border-color: rgba(102, 103, 107, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-state {
  top: 0px;
  left: 0px;
  width: 440px;
  height: 48px;
  position: absolute;
  border-color: transparent;
  border-radius: 5px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-focus {
  top: -2px;
  left: -2px;
  width: 444px;
  height: 52px;
  position: absolute;
  border-color: transparent;
  border-radius: 7px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-icon {
  top: 12px;
  left: 404px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text044 {
  top: 21px;
  left: 12px;
  color: var(--dl-color-global_gray-gray800);
  width: 384px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 24px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text046 {
  top: 6px;
  left: 12px;
  color: rgba(52, 54, 59, 1);
  width: 384px;
  height: auto;
  position: absolute;
  font-size: 12px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 15px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-group50 {
  width: 1155px;
  height: 42px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-tabs {
  top: 0px;
  left: 0px;
  width: 1155px;
  display: flex;
  position: absolute;
  align-items: flex-end;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: center;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-scrollable-tab-text-elements {
  width: 577.5px;
  display: flex;
  padding: 6px 0 0 0;
  position: relative;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 0px;
  border-radius: 5px 5px 0 0;
  flex-direction: column;
  justify-content: flex-end;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-tab {
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
  justify-content: flex-end;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-tab-content {
  display: flex;
  padding: 4px 16px 2px 16px;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: flex-end;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text048 {
  color: var(--dl-color-global_blue-blue500);
  width: 183px;
  height: auto;
  align-self: auto;
  text-align: center;
  line-height: 16px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-tab-bar {
  width: auto;
  height: 4px;
  position: relative;
  align-self: stretch;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-scrollable-tab-text-elements1 {
  width: 577.5px;
  display: flex;
  padding: 6px 0 0 0;
  position: relative;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 5px 5px 0 0;
  flex-direction: column;
  justify-content: flex-end;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-tab1 {
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
  justify-content: flex-end;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-tab-content1 {
  display: flex;
  padding: 4px 16px 5px 16px;
  overflow: hidden;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: flex-end;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text050 {
  color: var(--dl-color-global_gray-gray500);
  width: 183px;
  height: auto;
  align-self: auto;
  text-align: center;
  line-height: 16px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-tab-bar1 {
  width: auto;
  height: 1px;
  position: relative;
  align-self: stretch;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-footer {
  top: 911px;
  left: 0px;
  width: 1440px;
  height: 487px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-footer-legal {
  top: 429px;
  left: 0px;
  width: 1440px;
  display: flex;
  padding: 17px 0;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-global_blue-blue800);
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-utility-links {
  display: flex;
  padding: 0 70.68000030517578px;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame83 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 18px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text052 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 15px;
  margin-right: 17px;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-rectangle {
  width: 1px;
  height: 14px;
  position: relative;
  border-color: rgba(150, 150, 150, 1);
  margin-right: 17px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text054 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 15px;
  margin-right: 17px;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-rectangle-copy23 {
  width: 1px;
  height: 14px;
  position: relative;
  border-color: rgba(150, 150, 150, 1);
  margin-right: 17px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text056 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 15px;
  margin-right: 17px;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-rectangle-copy231 {
  width: 1px;
  height: 14px;
  position: relative;
  border-color: rgba(150, 150, 150, 1);
  margin-right: 17px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text058 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 15px;
  margin-right: 17px;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-rectangle-copy232 {
  width: 1px;
  height: 14px;
  position: relative;
  border-color: rgba(150, 150, 150, 1);
  margin-right: 17px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text060 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 15px;
  margin-right: 17px;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-rectangle-copy233 {
  width: 1px;
  height: 14px;
  position: relative;
  border-color: rgba(150, 150, 150, 1);
  margin-right: 17px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text062 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 15px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame326 {
  width: 188px;
  display: flex;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame831 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-country {
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 8px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-vector {
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  position: absolute;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text064 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: right;
  line-height: 15px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-horizon {
  top: 0px;
  left: 0px;
  width: 1440px;
  height: 429px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-brand-horizon {
  top: 0px;
  left: 0px;
  width: 1440px;
  height: 429px;
  position: absolute;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-brand-horizon1 {
  top: 4.901123046875px;
  left: 0px;
  width: 1440px;
  height: 424px;
  position: absolute;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-connect-with-us {
  top: 300px;
  left: -0.3400001525878906px;
  width: 203px;
  display: flex;
  padding: 0 0 0 70.68000030517578px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text066 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 12px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-social-icons {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-facebook {
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 12px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-twitter {
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 12px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-facebook1 {
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 12px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-youtube {
  width: 24px;
  height: 24px;
  position: relative;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame9 {
  top: 200px;
  left: 797px;
  width: 555px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-about-hills {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 70px;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text068 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 22px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text070 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 22px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text072 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 22px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text074 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-our-food-brands {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 70px;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text076 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 23px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text078 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 23px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text080 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 23px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text082 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 23px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text084 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-support {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame327 {
  display: flex;
  padding: 0 8px 0 0;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text086 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 23px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text088 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 23px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text090 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 23px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text092 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-s-v-g80whitescrim {
  top: 0px;
  left: -1px;
  width: 1442px;
  height: 1398px;
  position: absolute;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-modal {
  top: 527px;
  left: 420px;
  width: 601px;
  height: 344px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-modal1 {
  top: 0px;
  left: 0.5px;
  width: 600px;
  height: 344px;
  display: flex;
  position: absolute;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-modal-window {
  top: 0px;
  left: 0px;
  width: 600px;
  height: 344px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-modal-surface {
  top: 0px;
  left: 0px;
  width: 600px;
  height: 344px;
  position: absolute;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-cancel {
  top: 16px;
  left: 560px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame24 {
  top: 39px;
  left: 32px;
  width: 552px;
  height: 270px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text094 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 28px;
  margin-right: 0;
  margin-bottom: 60px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text-field {
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 60px;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-base-text-field {
  height: 72px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text-field-all {
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-containerfor-focus-state {
  display: flex;
  padding: 2px;
  position: relative;
  align-self: stretch;
  box-sizing: content-box;
  align-items: flex-start;
  border-color: transparent;
  border-radius: 5px;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-containerw-inner-textboxes {
  display: flex;
  padding: 5px 8px 6px 12px;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(68, 70, 75, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  justify-content: center;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text-box-group {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frametextbox2 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-bottom: 0px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text096 {
  color: var(--dl-color-global_gray-gray600);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 15px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frametextbox3 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text098 {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  flex-grow: 1;
  align-self: auto;
  text-align: left;
  line-height: 24px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-group54 {
  width: 552px;
  height: 48px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: stretch;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame27 {
  top: 0px;
  left: 0px;
  width: 552px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-button-masters1button {
  width: 96.17180633544922px;
  height: 48px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 22px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-container2 {
  top: 0px;
  left: 0px;
  width: 96px;
  height: 48px;
  position: absolute;
  border-color: transparent;
  border-radius: 5px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-state1 {
  top: 0px;
  left: 0px;
  width: 96px;
  height: 48px;
  position: absolute;
  border-color: transparent;
  border-radius: 5px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text100 {
  top: 13px;
  left: 12.879148483276367px;
  color: var(--dl-color-global_blue-blue500);
  width: 72px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: center;
  line-height: normal;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text102 {
  top: 13px;
  left: 12.87890625px;
  color: var(--dl-color-global_blue-blue500);
  width: 72px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: center;
  line-height: normal;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-focus1 {
  top: -2px;
  left: -2.1371512413024902px;
  width: 100px;
  height: 52px;
  position: absolute;
  border-color: transparent;
  border-radius: 7px;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame-button2 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-parent-button2 {
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 5px;
  justify-content: center;
  background-color: var(--dl-color-global_blue-blue500);
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-button-internal2 {
  display: flex;
  padding: 12px 22px;
  position: relative;
  align-items: center;
  border-color: transparent;
  justify-content: center;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-frametextbox4 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.p11440312h-g-m-home-adminmember-vieweditgroupnickname-text104 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: normal;
  margin-right: 0;
  margin-bottom: 0;
}
