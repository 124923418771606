.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #0054a4 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
  bottom: 35%;
  position: absolute;
  right: 52%;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
